<nav class="navbar navbar-expand-lg navbar-dark">
    <div class="container-fluid">
        
        <div class="navbar-header">
            <a [routerLink]="['/']" class="navbar-brand">Jack Holm</a>
            
        </div>
        <button aria-label="Toggle Navigation" type="button" class="navbar-toggler" data-toggle="collapse" data-target="#mainNavBar">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="mainNavBar">
            <ul class="nav navbar-nav">
                <li class="nav-item" [routerLinkActive]="['link-active']">
                    <a [routerLink]="['/']" class="nav-link">About</a>
                </li>
                <li class="nav-item" [routerLinkActive]="['link-active']">
                    <a [routerLink]="['/projects']" class="nav-link">Projects</a>
                </li>
                <li class="nav-item" [routerLinkActive]="['link-active']">
                    <a [routerLink]="['/experience']" class="nav-link">Experience</a>
                </li>
            </ul>
        </div>
    </div>
</nav>