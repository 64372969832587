<div class="container m-1 p-5">
    <h1>Experience</h1>
    <section class="p-2 mb-1">
        <div class="row">
            <div class="col-md-9">
                <h3 class="mb-0">Application Developer</h3>
                <div class="mb-3">Institute for Intergovernmental Research (IIR)</div>
                <p>Developing with ASP.NET and Angular
                </p>
            </div>
            <div class="col-md-3 text-right">
                <b>November 2022 - Present</b>
            </div>
        </div>
        <div class="row">
            <div class="col-md-9">
                <h3 class="mb-0">Software Developer</h3>
                <div class="mb-3"><a href="https://www.anagogicsoftware.com">Anagogic Software</a></div>
                <ul>
                    <li>
                        Developed with C# for mobile game apps for release on Android and iOS
                    </li>
                    <li>
                        Worked with client to adapt projects to mobile app
                    </li>
                    <li>
                        Coordinated with localization and marketing for app release
                    </li>
                </ul>
            </div>
            <div class="col-md-3 text-right">
                <b>July 2017 - Present</b>
            </div>
        </div>
        <div class="row">
            <div class="col-md-9">
                <h3 class="mb-0">Internship</h3>
                <div class="mb-3">Digi International</div>
                <p>Developed with Python and MySQL in a project to analyze 
                    the level of security of various Digi products currently in use. 
                    This created a database containing IP addresses of products 
                    around the world and data that indicated how secure they were.
                </p>
            </div>
            <div class="col-md-3 text-right">
                <b>June 2015 - August 2015</b>
            </div>
        </div>
    </section>
    <h1>Education</h1>
    <section class="p-2">
        <div class="row">
            <div class="col-md-9">
                <h3 class="mb-0">South Dakota State University</h3>
                <div class="mb-3">B.S. - Computer Science</div>
                <p>
                    GPA: 3.14
                </p>
            </div>
            <div class="col-md-3 text-right">
                <b>August 2013 - May 2017</b>
            </div>
        </div>
    </section>
</div>