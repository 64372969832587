<div class="container m-1 p-5">
    <h1>Projects</h1>
    <section class="p-2 mb-1">
        <div class="row border-bottom mb-3">
            <div class="col-md-8">
                <h3 class="mb-3">Synchro Slide</h3>
                <ul>
                    <li>Android app</li>
                    <li>Created using C# and Unity 3D</li>
                    <li>Self published to Apple App Store and Google Play Store</li>
                    <li>Over 2000 downloads on Google Play Store</li>
                </ul>
                <p>Read more: <a href="https://edamame.reviews/synchro-slide/">https://edamame.reviews/synchro-slide/</a></p>
                <a aria-label="Google Play" id="googlePlayBadge" href='https://play.google.com/store/apps/details?id=com.JackHolm.Synchro_Slide&hl=en_US&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                    <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                </a>
            </div>
            <div class="col-md-4 text-right">
                <b>July 2017 - June 2018</b>
            </div>
        </div>
        <div class="row border-bottom mb-3">
            <div class="col-md-8">
                <h3 class="mb-3">1 Minute Post</h3>
                <p> Twitter-like social media site with limited time frame posts. Created with 
                    ASP.NET/Angular</p>
                <p><a class="demoLink" href="https://jackholm.com/1MinutePost/">Demo</a></p>
                <a aria-label="Github repository" class="icon" href="https://github.com/jackdholm/1MinutePost">
                    <i class="fab fa-github"></i>
                </a>
            </div>
        </div>
        <div class="row border-bottom mb-3">
            <div class="col-md-8">
                <h3 class="mb-3">Dex Transactions</h3>
                <p>Creates an easily readable list of decentralized Ethereum exchange transactions</p>
                <p><a class="demoLink" href="http://jackholm.com/DexTransactions/">Demo</a></p>
                <a aria-label="Github repository" class="icon" href="https://github.com/jackdholm/Dex_Transactions">
                    <i class="fab fa-github"></i>
                </a>
            </div>
        </div>
        <div class="row border-bottom mb-3">
            <div class="col-md-8">
                <h3 class="mb-3">Keyboard Notes</h3>
                <p>WPF desktop note application using vim-like keyboard controls</p>
                <a aria-label="Github repository" class="icon" href="https://github.com/jackdholm/Keyboard_Notes">
                    <i class="fab fa-github"></i>
                </a>
            </div>
        </div>
        <div class="row border-bottom mb-3">
            <div class="col-md-8">
                <h3 class="mb-3">Day Counter</h3>
                <p>Java Android app for a list that keeps track of the days that have passed since different dates</p>
                <a aria-label="Github repository" class="icon" href="https://github.com/jackdholm/DayCounter">
                    <i class="fab fa-github"></i>
                </a>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8">
                <h3 class="mb-3">Typing Test</h3>
                <ul>
                    <li>Typing speed test created in JavaScript using jQuery</li>
                    <li>Tests typing speed using 1000 most common words</li>
                </ul>
                <p>
                    <a href="https://jackdholm.github.io/test.html">https://jackdholm.github.io/test.html</a>
                </p>
                <a aria-label="Github repository" class="icon" href="https://github.com/jackdholm/Typing_Test">
                    <i class="fab fa-github"></i>
                </a>
            </div>
        </div>
        
    </section>
</div>