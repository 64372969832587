<div class="container m-1 p-5" >
    <h1 class="mb-0">
        Jack Holm
    </h1>
    <div class="ml-1">
        <a>jackdholm1@gmail.com</a>
    </div>
    <section class="ml-0 my-5">
        Software developer with experience in .NET, Python, and Unity 
    </section>

    <section class="my-5">
        <a aria-label="LinkedIn Profile" class="icon" href="https://www.linkedin.com/in/jackdholm">
                <i class="fab fa-linkedin"></i>
        </a>
        <a aria-label="Github Profile" class="icon" href="https://github.com/jackdholm">
                <i class="fab fa-github"></i>
        </a>
    </section>
</div>